<template lang="pug">
div
  Header
  .Page
    .container-logo.MeetingPage
      transition(name="fade" mode="out-in")
        div(v-if="!paramsFail")
          h6(style="margin-top: 3vh;").buttonText Cargando parámetros...
          .mt-3.maxSizingProgress
            Spinner
        h6(style="margin-top: 3vh; text-align: center; color: firebrick" v-else) La solicitud tiene parámetros inválidos y no puedes avanzar. Contacta con soporte técnico.
</template>
<script>
/* eslint-disable no-unreachable */
import LogoColmedica from "@/assets/colmedica_logo.png";
import { b64_to_utf8, try_json, decodeUrlComponent } from "@/utils/decoder";
import LS from "@/helpers/localStorage";
import { get as _get } from "lodash";
import { mapActions, mapState } from "vuex";
import Bowser from "bowser";
import { getBranch, getCompany } from "../../helpers/Meet";
import Spinner from "../components/Spinner";
import Header from "./components/Header.vue";

export default {
  name: "Decoder",

  components: {
    Spinner,
    Header
  },

  created() {
    this.startupLocal();
  },

  data: () => ({
    LogoColmedica,
    paramsFail: false
  }),

  computed: {
    ...mapState({
      version: state => state.version,
      turn: state => state.turn,
      environment: state => state.environment,
      sessionMaster: state => state.sessionMaster,
      env: state => state.env,
      services: state => state.autoservice.servicesAvailable
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    evaluateCompany() {
      return this.env.VUE_APP_EVALUATE_COMPANY === "true";
    },

    comp() {
      return this.env.VUE_APP_COMPANY;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO_OA || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      setSessionMaster: "setSessionMaster",
      cleanTurn: "cleanTurn",
      getTurnRequest: "getTurnRequest",
      endMeet: "meeting/endMeet",
      feclient_data: "feclient_data",
      fdclient_data: "fdclient_data",
      setBranchInfo: "setBranchInfo",
      putLog: "log/put",
      sendTracking: "virtualrow/handleTracking",
      goToView: "virtualrow_atril_video/goToView",
      setClient: "virtualrow_atril_video/setClient",
      updateB64Final: "virtualrow_atril_video/updateB64Final",
      setService: "virtualrow_atril_video/setService"
    }),
    async checkTurn() {
      if (this.turn) {
        let result;
        // let result = await this.getTurnRequest(this.turn.code);
        try {
          result = await this.getTurnRequest(this.turn.code);
        } catch (error) {
          this.putLog({
            name:
              "Decoder. Error no restrictivo. Error en verificación del turno anterior.",
            checked: false,
            message: error
          });
        }
        if (
          typeof result == "object" &&
          result.status !== "WAITING_TO_BE_CALLED" &&
          result.status !== "ANNOUNCED"
        ) {
          this.cleanTurn();
          this.putLog({
            name:
              "Decoder. El turno anterior fue eliminado para crear un nuevo turno."
          });
        }
      }
    },
    async startupLocal() {
      let sessionMaster = this.sessionMaster;
      let { pr } = this.$route.query;
      console.log(pr);
      let rawB64 = decodeUrlComponent(pr);
      await this.checkTurn(rawB64);
      this.setSessionMaster(sessionMaster);
      this.setBranchInfo(null);
      LS.setItem("user_type", "client");
      const browser = Bowser.getParser(window.navigator.userAgent);
      let client = {
        sm: this.sessionMaster,
        bsix: {
          v: this.version,
          e: this.environment
        },
        br: {
          n: browser.getBrowser().name,
          v: browser.getBrowser().version
        },
        os: {
          n: browser.getOS().name,
          v: browser.getOS().version,
          vn: browser.getOS().versionName
        },
        pl: browser.getPlatformType(),
        en: {
          n: browser.getEngine().name,
          v: browser.getEngine().version
        }
      };
      let json_value;
      try {
        json_value = try_json(b64_to_utf8(pr));
        LS.setItem("rawB64", rawB64);
        LS.setItem("name_user", _get(json_value, "na", ""));
        LS.setItem("last_name_user", _get(json_value, "ap", ""));
        LS.setItem("marcacion", _get(json_value, "ma", ""));
        LS.setItem("tipide", _get(json_value, "ti", ""));
        LS.setItem("user_id", _get(json_value, "nu", ""));
        LS.setItem("phone_number", _get(json_value, "ce", ""));
        LS.setItem("e_mail", _get(json_value, "em", ""));
        LS.setItem("token_col", _get(json_value, "to", ""));
        LS.setItem("sucursal", _get(json_value, "su", ""));
        LS.setItem("tramite", _get(json_value, "tr", ""));
        LS.setItem("fuente", _get(json_value, "fu", ""));
        LS.setItem("naturaleza", "normal");
        LS.setItem("appointment_code", _get(json_value, "ac", ""));
        this.feclient_data(pr);
        this.fdclient_data({ ...json_value, client });
        this.putLog({
          name:
            "Decoder. Los parámetros recibidos se cargaron para hacer la solicitud del turno."
        });
      } catch (error) {
        this.putLog({
          name:
            "Decoder. La conversión de datos para generar un turno ha fallado.",
          checked: false,
          message: `Query string recibido: ${JSON.stringify(
            this.$route.query
          )}. Error: ${error}`
        });
        this.$rollbar.warning(
          "Invalid query string was sended to decode. It stop the navigation.",
          { error, query: this.$route.query }
        );
      }
      if (json_value) {
        // check if fu (fuente) is "turno" for the flow can continue
        console.log("Deberia sacarme?");
        console.log(json_value);
        console.log(json_value.fu);
        if (json_value.fu == "app" || json_value.fu == "web") {
          json_value.fu = json_value.fu + "_redirect";
          this.setClient({
            NumeroIdentificacion: json_value.nu,
            Celular: json_value.ce,
            Correo: json_value.em,
            Nombres: json_value.na,
            PrimerApellido: json_value.ap,
            Resultado: 1,
            ...json_value
          });
          this.updateB64Final(json_value);
          if (json_value.se) {
            this.setService(this.services.find(s => s.id == json_value.se));
          }
          setTimeout(() => {
            this.goToView({view: "IndexSchedules"});
            setTimeout(() => {
              this.$router.push({path: "/fv_atril_video/", query: this.$route.query});
            }, 1000);
          }, 1000);
          return;
        }

        LS.setItem("marcacion", json_value.ma);
        LS.setItem("tramite", json_value.tr);
        LS.setItem("fuente", json_value.fu);

        // appointment
        if (json_value.fu == "appointment") {
          console.log("probando");
        }

        setTimeout(() => {
          // tracking
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-solicitando-turno",
            debmedia_turn_code: "",
            url_origen: window.location.href,
            origen: _get(json_value, "fu", ""),
            mensajes: ""
          };

          this.sendTracking(trackingData);
        }, 1000);

        if (!this.evaluateCompany)
          return this.$router.push({
            name: "ProfileKiosco46",
            params: { havedt: true },
            query: { branchid: this.$route.query.branchid }
          });
        else {
          try {
            const company = await getCompany(this.comp);
            const branch = _get(
              Object.keys(company).filter(
                name => company[name].branch_id == _get(json_value, "su")
              ),
              "[0]"
            );
            const branchInfo = await getBranch(this.comp, branch);
            this.setBranchInfo(branchInfo);

            if (branchInfo.activeBranch)
              return this.$router.push({
                name: "ProfileKiosco46",
                params: { havedt: true },
                query: { branchid: this.$route.query.branchid }
              });
          } catch (error) {
            this.putLog({
              name:
                "Decoder. Error restrictivo. Falló la verificación de la compañía y la sucursal.",
              checked: false,
              message: error
            });
            console.error("Error obtaining company", error);
          }
          this.$router.push({
            path: "/fv_atril_video",
            query: this.$route.query
          });
          return this.goToView({ view: "OutOperationsFinish" } );
        }
      } else {
        this.paramsFail = true;
        await this.endMeet().catch(error =>
          console.error("Error on ending meet in firt page", error)
        );
        await this.cancelTurn().catch(error =>
          console.error("Error on cancel turn in firt page", error)
        );
      }
    }
  }
};
</script>

<style scoped>
.container-logo {
  flex-direction: column;
}
.maxSizingProgress {
  display: flex;
  justify-content: center;
}

.MeetingPage {
  background-color: #f2f4f8;
  padding: 1.5rem;
  width: 100%;
  max-width: 600px;
  height: fit-content;
}

.buttonText {
  font-size: 28px;
}
</style>
